<template>
  <div class="refer-block red-border d-flex rem-md-8 rem-4 pa-0 mb-5">
    <div class="refer-level px-2 white--text">{{ level }}</div>
    <div class="white px-2 secondary--text">{{ account }}</div>
  </div>
</template>
<script>
export default {
  props: {
    level: {
      type: [Number, String],
      default: '-'
    },
    account: String
  }
}
</script>
<style lang="scss">
.refer-block{
  position: relative;
  z-index: 2;
  .refer-level{
    background-color: rgb(255, 50, 50);
  }
}
</style>