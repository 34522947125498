<template>
  <div class="member-refer-list d-flex justify-center">
    <div class="card-wrap d-flex flex-column align-center w-full">
      <pageTitle icon="icon-member-red" text="myRefers"></pageTitle>
      
      <div id="refer-list" class="d-flex justify-space-between w-full" style="position: relative;">
        <canvas id="canvas" style="position: absolute;">
        </canvas>
        <div class="curr-account">
          <referBlock id="currAccount" :account="currAccount" :level="currLevel"></referBlock>
        </div>
        <div class="refer-account">
          <referBlock :id="`refer${i}`" class="can-click" :account="item.name" :level="item.level" v-for="(item, i) in items" :key="item.id" @click.native="changeAccount(item)"></referBlock>
        </div>
      </div>
      <div class="text-right w-full can-click" style="position: relative; z-index: 5;" v-if="currAccount!==`0${$store.state.userInfo.phone_number}`" @click="changeAccount({id: $store.state.userInfo.id, name: `0${$store.state.userInfo.phone_number}`, level: $store.state.userInfo.level})">{{ $t('backMyRefers') }}</div>
    </div>
  </div>
</template>
<script>
import base from '@/mixin/base'
import pageTitle from '@/components/pageTitle.vue'
import referBlock from '@/components/referBlock.vue'
export default {
  name: "Member-Refer-List",
  mixins: [base],
  data(){
    return {
      currLevel: this.$store.state.userInfo.level,
      currAccount: `0${this.$store.state.userInfo.phone_number}`,
      items: [],
    }
  },
  components:{
    pageTitle,
    referBlock
  },
  watch: {
    "$store.state.nowWidth": {
      handler: function(){
        this.drawLine()
      }
    }
  },
  methods:{
    async getRect(idName){
      let ele = await document.getElementById(idName)
      if (ele){
        let rect = ele.getBoundingClientRect()
        return rect
      }
    },
    async drawLine(){
      console.log('here')
      var c = document.getElementById("canvas");
      var ctx = c.getContext("2d");

      let currRect = await this.getRect('currAccount')
      let firstRect = await this.getRect('refer0')
      let referList = await this.getRect('refer-list')
      c.width = referList.width
      c.height = referList.height
      
      if (this.items.length !== 0){
        ctx.strokeStyle = "red";
        ctx.lineJoin = "round";

        ctx.moveTo(0, currRect.height/2);
        ctx.lineTo(c.width, firstRect.height/2);
        ctx.stroke();

        let rect
        for (let i=1; i<this.items.length; i++){
          rect = await this.getRect(`refer${i}`)
          ctx.moveTo(c.width/2, firstRect.height/2);
          ctx.lineTo(c.width/2, firstRect.height*(i+0.5) + 20*(i));
          ctx.lineTo(c.width, rect.height*(i+0.5) + 20*i);
          ctx.stroke();
        }
      }else{
        ctx.beginPath();
        ctx.rect(0, 0, c.width, c.height);
        ctx.fillRect();
        ctx.stroke();
      }
    },
    async getReferList(id){
      try{
        this.items = []
        let result = await this.$store.dispatch('referList', id)
        if (result.status === 200){
          if (result.data !== null){
            console.log('result.data', result.data)
            let _this = this
            result.data.forEach(item=>{
              _this.items.push({
                name: `0${item.phone_number}`,
                level: item.level,
                id: item.id
              })
            })
          }
          await this.drawLine()
        }
      }catch(error){
        console.log('error', error)
      }
    },
    async changeAccount(item){
      console.log('item', item)
      this.currAccount = item.name
      this.currLevel = item.level
      await this.getReferList(item.id)
    }
  },
  async mounted(){
    await this.getReferList(this.$store.state.userInfo.id)
  }
}
</script>